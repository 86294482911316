<template>
  <div
    class="page-container"
    :class="{
      'page-container_closed': isMenuClosed,
      'page-container_open': !isMenuClosed,
    }"
  >
    <div
      class="menu"
      :class="{ menu_closed: isMenuClosed, menu_open: !isMenuClosed }"
    >
      <div class="menu_top">
        <div :class="menuHeaderClass">
          <img class="logo menu__header__logo" src="/images/logo-aml.svg" />
          <img class="logo menu__header__logo_min" :src="srcMinLogo" />
        </div>
        <div class="menu__separator"></div>
        <div class="project-title">
          {{ $store.getters["auth/me"].tenant.name }}
        </div>
        <nav class="menu__nav">
          <router-link
            v-for="(menu, index) in nav"
            v-bind:key="index"
            class="menu__nav__item"
            :to="{ path: menu.url }"
            :class="{ menu__nav__item_selected: menu.url === currentRouteUrl }"
          >
            <div class="menu__nav__icon"><img :src="menu.srcImg" /></div>
            <div class="menu__nav__title">{{ menu.name }}</div>
          </router-link>
        </nav>
      </div>
      <div class="menu_bottom">
        <div class="menu__note">
          <p>
            Із питаннями щодо роботи системи телефонуйте
            <a href="tel:+380504588810">+380 50 458 88 10</a>
          </p>
        </div>
        <div class="menu__separator"></div>
        <div class="menu__nav__item" @click="logout">
          <div class="menu__nav__icon">
            <img src="/images/logout.svg" alt="logout" />
          </div>
          <div class="menu__nav__title">Вийти</div>
        </div>
      </div>
      <div class="menu__toggle" @click="isMenuClosed = !isMenuClosed"></div>
    </div>
    <div class="page__wrapper">
      <div class="container-fluid">
        <transition>
          <router-view />
        </transition>
      </div>
    </div>
    <vue-snotify class="material" />
  </div>
</template>

<script>
import nav from "./../_nav";
import api from "../api/api";

export default {
  name: "DefaultContainer",
  mounted() {
    const userTenant = this.$store.getters["auth/me"].tenant.alias;

    this.nav = nav.items.filter((item) => {
      if (item.canTenant === userTenant) {
        return true;
      } else {
        if (item.can === undefined) {
          return true;
        }

        return this.$auth.can(item.can);
      }
    });
  },
  data() {
    return {
      isMenuClosed: false,
      nav: [],
    };
  },
  computed: {
    currentRouteUrl() {
      return this.$route.path;
    },
    srcMinLogo() {
      if (process.env.VUE_APP_IS_DEMO === "true")
        return "/images/logo-aml-min_demo.svg";
      return "/images/logo-aml-min.svg";
    },
    menuHeaderClass() {
      if (process.env.VUE_APP_IS_DEMO === "true") return "menu__header_demo";
      return "menu__header";
    },
    name() {
      return this.$route.name;
    },
    list() {
      return this.$route.matched.filter((route) => route.meta.label);
    },
  },
  methods: {
    logout() {
      api.logoutUser().then(() => {
        this.$store.dispatch("auth/clearToken");
        this.$router.push({ name: "Login" });
      });
    },
  },
};
</script>
<style scoped lang="scss">
@font-face {
  font-family: "ProximaNova";
  src: url(/fonts/ProximaNova-Bold.ttf);
  font-weight: 900;
}

@font-face {
  font-family: "ProximaNova";
  src: url(/fonts/ProximaNova-Regular.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "ProximaNova";
  src: url(/fonts/ProximaNova-Light.ttf);
  font-weight: 300;
}

menu {
  font-family: ProximaNova;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.page__wrapper {
  background-color: #f7f8fb;
  padding: 30px;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  -webkit-transform: translateX(256px);
  transform: translateX(256px);
}

.page-container {
  position: relative;
  overflow-x: hidden;
}

.page-container_open .page__wrapper {
  width: calc(100% - 256px);
  -webkit-transform: translateX(256px);
  transform: translateX(256px);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.page-container_closed .page__wrapper {
  width: calc(100% - 64px);
  -webkit-transform: translateX(64px);
  transform: translateX(64px);
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.menu {
  height: 100vh;
  background-color: #2d205d;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: fixed;
  overflow: hidden;
  z-index: 1;
  left: 0px;
  top: 0px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  font-family: ProximaNova;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.2;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;

  &:hover {
    overflow-y: scroll;
  }
}

.menu {
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #d3cdeb;
    border-radius: 10px;
  }
}

.menu_top {
  width: 256px;
}

.menu_bottom {
  bottom: 0px;
  width: 256px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.menu__separator {
  width: 100%;
  max-width: 216px;
  height: 1px;
  background-color: rgba(255, 255, 255, 0.15);
  margin: 0 auto;
}

.menu .project-title {
  color: #4ebd74;
  font-weight: 900;
  font-size: 14px;
  line-height: 24px;
  padding: 20px;
  visibility: visible;
  opacity: 1;
}

.menu_open {
  width: 256px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.menu_open .menu__note,
.menu_open .menu__nav__item,
.menu_open .menu__header__logo,
.menu_open .menu__header::after {
  visibility: visible;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu_open .menu__header__logo_min {
  display: none;
}

.menu_open .menu__toggle {
  left: 254px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu_closed {
  width: 64px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
}

.menu_closed .menu__note,
.menu_closed .menu__nav__title,
.menu_closed .menu__header::after {
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu_closed .menu__header__logo {
  display: none;
}

.menu_closed .project-title {
  visibility: hidden;
  opacity: 0;
}

.menu_closed .menu__toggle {
  left: 62px;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu_bottom {
  margin-top: auto;
}

.menu__header,
.menu__header_demo {
  padding: 12px 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 64px;
}

.menu__header_demo::after {
  content: "demo";
  background: linear-gradient(89.11deg, #4ebd74 38.71%, #a2f25e 145.73%);
  border-radius: 4px;
  padding: 4px;
  display: block;
  height: 22px;
  margin-left: 16px;
  color: #ffffff;
  font-weight: 700;
  box-sizing: unset;
  -webkit-box-sizing: unset;
  font-family: ProximaNova;
  line-height: normal;
}

.menu__header__logo_min {
  display: block;
}

.menu__nav {
  position: relative;
}

.menu__nav__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 16px 20px;
  position: relative;
  cursor: pointer;
  text-decoration: none;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu__nav__item_selected {
  background: #9e8ed028;
}

.menu__nav__item_selected::after {
  content: "";
  background: #ffffff;
  border-radius: 10px;
  height: 40px;
  width: 2px;
  position: absolute;
  right: 4px;
}

.menu__nav__item_selected .menu__nav__title {
  color: #ffffff;
}

.menu__nav__item:hover .menu__nav__title {
  color: #ffffff;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}

.menu__nav__icon {
  width: 24px;
  height: 25px;
}

.menu__nav__icon svg path {
  opacity: 1;
  fill-opacity: 1;
}

.menu__nav__title {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  margin-left: 10px;
}

.menu__note {
  padding: 20px;
}

.menu__note p {
  background: #9e8ed028;
  color: rgba(255, 255, 255, 0.6);
  padding: 20px;
  border-radius: 10px;
}

.menu__note a {
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: #ffffff;
  text-decoration: none;
  margin-top: 10px;
  font-weight: 600;
}

.menu__toggle {
  cursor: pointer;
  width: 16px;
  height: 64px;
  background: #2d205d;
  border-radius: 0px 12px 12px 0px;
  position: fixed;
  top: 0px;
  left: 256px;
  background-image: url(/images/chevron.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
</style>
